import React, { useState, useEffect } from 'react';
import { SpinnerCircular } from 'spinners-react';
import './ArticlesPage.scss';

import { getArticles } from 'api/article';
import { formatDate } from 'utils/helper';
import { loadingStatuses } from 'utils/constants';

import PlaceholderImage from 'assets/images/placeholder.webp';

export const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(() => {
    const fetchData = async () => {
      const articles = await getArticles();

      setArticles(articles);
      setLoadingStatus(loadingStatuses.LOADED);
    }

    fetchData();
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='Spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <div className='ArticlesPage'>
          <h2 className='Title'>Навіны</h2>
          <div className='ArticlesPage-List'>
            {
              articles.map((article) => {
                const { id, title, date_at, link, cover } = article;

                return <a key={id} className='ArticlesPage-Item' href={link} rel='noreferrer' target='_blank'>
                  <div className='Item-Image'>
                    <img
                      src={cover}
                      alt="cover"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PlaceholderImage;
                      }
                    }/>
                  </div>
                  <p className='Item-Title'>{title}</p>
                  <p className='Item-Date'>{formatDate(date_at)}</p>
                </a>
              })
            }
          </div>
        </div>
      </>
    }
    default: {
      <div></div>
    }
  }
}
