import { SET_SONGS, SET_SONG_INDEX, SET_PLAYING, SET_LOADING, SET_DURATION, SET_TIME, SET_ERROR } from '../constants';

export const setSongs = (songs) => ({ type: SET_SONGS, payload: songs });

export const setSongIndex = (index) => ({ type: SET_SONG_INDEX, payload: index });

export const setPlaying = (status) => ({ type: SET_PLAYING, payload: status });

export const setLoading = (status) => ({ type: SET_LOADING, payload: status });

export const setDuration = (duration) => ({ type: SET_DURATION, payload: duration });

export const setTime = (time) => ({ type: SET_TIME, payload: time });

export const setError = (error) => ({ type: SET_ERROR, payload: error });
