import { useEffect } from 'react';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga';

export const PageTracker = (props) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-FY6W4M67KL');
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <>{props.children}</>
};
