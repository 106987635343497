import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import cx from 'classnames';

import { getProject } from 'api/project';
import { formatDate } from 'utils/helper';

import './ProjectPage.scss';
import PlaceholderImage from 'assets/images/placeholder.webp';

export const ProjectPage = (props) => {
  const [project, setProject] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const project = await getProject(id);

      setProject(project);
    }

    fetchData();
  }, [id]);

  if (project) {
    const { title } = project;

    return <>
      <div className='ProjectPage'>
        <div className="Project-Information">
          <div className="Project-Group">
            <div className='project-title'>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
        <div className='Project-Links'>
          {
            project.links.sort((a, b) => (new Date(b.date_at) - new Date(a.date_at))).map(({ id, title, cover, url, date_at }) => {
              if (url.includes('youtube')) {
                return <ReactPlayer
                  className='ReactPlayer'
                  url={url}
                  config={{ file: { attributes: { preload: 'none' } } }}
                  controls
                  style={{ maxWidth: '40%' }}
                />
              } else {
                return <a
                  key={id}
                  href={url}
                  className={cx("Link")}
                  rel='noreferrer'
                  target='_blank'
                >
                  <div className="Link-Image">
                    <img
                      src={cover || project.cover}
                      alt='cover'
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PlaceholderImage;
                      }}
                    />
                  </div>
                  <div className="Link-Title">
                    <p>{title}</p>
                    <p className="Link-Date">{formatDate(date_at)}</p>
                  </div>
                </a>
              }
            })
          }
        </div>
      </div>
    </>
  } else {
    return <div className='Spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
    </div>
  }
}
