import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.scss';

import { formatDate } from 'utils/helper';
import { getAlbums } from 'api/album';
import { getArticles } from 'api/article';

import BackgroundImage from 'assets/images/background.webp';
import Image1 from 'assets/images/26 4.webp';
import Image2 from 'assets/images/26 5.webp';
import Image3 from 'assets/images/26 6.webp';
import YoutubeIcon from 'assets/images/icons/youtube.svg';
import FacebookIcon from 'assets/images/icons/facebook.svg';
import PatreonIcon from 'assets/images/icons/patreon.svg';
import PaypalIcon from 'assets/images/icons/paypal.svg';

export const HomePage = () => {
  const [albums, setAlbums] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const albums = await getAlbums();
      const articles = await getArticles();

      setAlbums(albums);
      setArticles(articles);
    }

    fetchData();
  }, []);

  return <>
    <div className='HomePage'>
      <section className='Main'>
        <h1 className='Title'>Тацяна Грыневіч-Матафонава</h1>
        <img src={BackgroundImage} alt='background'/>
      </section>
      <section className='HomePage-About'>
        <div className='HomePage-Information'>
          <div className='box1'>
            <p>Тацяна Грыневіч-Матафонава — беларуская спявачка, музыка. Нарадзілася ў Ашмянах, скончыла Маладзечанскую музычную вучэльню і Універсітэт культуры ў Мінску. Працавала ў Белдзяржфілармоніі</p>
          </div>
          <div className='box2'>
            <img src={Image1} alt='image1'/>
          </div>
          <div className='box3'>
            <p>Асноўныя захапленні — беларускі раманс, дзіцячыя народныя і школьныя песні, народныя танцы. Тацяна нясе беларускаць ужо як дваццаць гадоў і робіць велізарную працу — адраджэнне беларускага танца разам з Капэляй «На таку», запіс дыскаў з дзіцячымі песнямі, рамансамі, танцамі, песнямі аб Беларусі, вершамі са школьнай праграмы і галасамі паэтаў</p>
          </div>
          <div className='box4'>
            <p>Больш за 10 гадоў Тацяна ездзіла па школах Беларусі з выступамі, каб перадаць дзецям любоў да Радзімы, да беларускіх песень і танцаў, каб натхніць настаўнікаў на беларускую працу</p>
            <img src={Image3} alt='image3'/>
          </div>
          <div className='box5'>
            <img src={Image2} alt='image2'/>
            <p>Слухаць і глядзець Тацяну можна ў <a target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UCwIJKisuaiFbY8Yo73gJ7-Q'>YouTube</a> і <a target='_blank' rel='noreferrer' href='https://www.facebook.com/profile.php?id=100002680600277'>Facebook</a>. Падтрымаць праз <a target='_blank' rel='noreferrer' href='https://www.patreon.com/hrynievich'>Patreon</a> і <a target='_blank' rel='noreferrer' href='adam.matafonov@gmail.com'>PayPal</a></p>
            <div className='HomePage-Socials'>
              <a href='https://www.youtube.com/channel/UCwIJKisuaiFbY8Yo73gJ7-Q' rel='noreferrer' target='_blank'>
                <img src={YoutubeIcon} alt='youtube'/>
              </a>
              <a href='https://www.facebook.com/profile.php?id=100002680600277' rel='noreferrer' target='_blank'>
                <img src={FacebookIcon} alt='facebook'/>
              </a>
              <a href='https://www.patreon.com/hrynievich' rel='noreferrer' target='_blank'>
                <img src={PatreonIcon} alt='patreon'/>
              </a>
              <a href='mailto:adam.matafonov@gmail.com' rel='noreferrer' target='_blank'>
                <img src={PaypalIcon} alt='paypal'/>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className='HomePage-Albums'>
        <h2 className='Title'>Альбомы</h2>
        <div className='Albums-List'>
          {
            albums.sort((a, b) => (new Date(b.date_at) - new Date(a.date_at))).slice(0, 6).map(({ id, title, cover, date_at }) => {
              return <Link key={id} className='Albums-Item' to={`/albums/${id}`}>
                <div className='Item-Image'>
                  <img src={cover} alt='cover'/>
                </div>
                <p className='Item-Title'>{title}</p>
                <p className='Item-Date'>{date_at.slice(0, 4)}</p>
              </Link>
            })
          }
        </div>
        <Link to='/albums' className='Button'>
          Усе альбомы
        </Link>
      </section>
      <section className='HomePage-Articles'>
        <h2 className='Title'>Навіны</h2>
        <div className='Articles-List'>
          {
            articles.sort((a, b) => (new Date(b.date_at) - new Date(a.date_at))).slice(0, 3).map(({ id, title, description, date_at, link }) => {
              return <a key={id} className='Articles-Item' href={link} target='_blank' rel='noreferrer'>
                <p className='Item-Date'>{formatDate(date_at)}</p>
                <p className='Item-Title'>{title}</p>
                <p className='Item-Description'>{description}</p>
              </a>
            })
          }
        </div>
        <Link to='/articles' className='Button'>
          Усе навіны
        </Link>
      </section>
    </div>
  </>
}
