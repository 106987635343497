import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.scss';

import { Header, Footer } from 'common';

import { Player } from 'components';

import {
  HomePage,
  ArticlesPage,
  AlbumsPage,
  AlbumPage,
  ProjectsPage,
  ProjectPage,
} from 'pages';

import { PageTracker, ScrollToTop } from 'utils/hooks';

function App() {
  return (
    <Router>
      <div>
        <Header/>
        <div className='Container'>
          <ScrollToTop>
            <PageTracker>
              <Routes>
                <Route path='/albums' element={<AlbumsPage />} />
                <Route path='/albums/:id' element={<AlbumPage/>} />
                <Route path='/articles' element={<ArticlesPage />} />
                <Route path='/projects' element={<ProjectsPage />} />
                <Route path='/projects/:id' element={<ProjectPage />} />
                <Route path='/' element={<HomePage />} />
              </Routes>
            </PageTracker>
          </ScrollToTop>
        </div>
        <Footer/>
        <Player/>
      </div>
    </Router>
  );
}

export default App;
