import { SET_SONGS, SET_SONG_INDEX, SET_PLAYING, SET_LOADING, SET_DURATION, SET_TIME, SET_ERROR } from '../constants';

export default function appReducer(state = {}, action) {
  switch(action.type) {
    case SET_SONGS: {
      return {
        ...state,
        songs: action.payload,
      }
    }
    case SET_SONG_INDEX: {
      return {
        ...state,
        songIndex: action.payload,
      }
    }
    case SET_PLAYING: {
      return {
        ...state,
        playing: action.payload,
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case SET_DURATION: {
      return {
        ...state,
        duration: action.payload,
      }
    }
    case SET_TIME: {
      return {
        ...state,
        time: action.payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
