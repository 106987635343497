import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import './ProjectsPage.scss';

import { getProjects } from 'api/project';
import { loadingStatuses } from 'utils/constants';

export const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(() => {
    const fetchData = async () => {
      const projects = await getProjects();

      setProjects(projects);
      setLoadingStatus(loadingStatuses.LOADED);
    }

    fetchData();
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='Spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <div className='ProjectsPage'>
          <h2 className='Title'>Праекты</h2>
          <div className='ProjectsPage-List'>
            {
              projects.map((project) => {
                const { id, title, cover } = project;

                return <Link key={id} className='ProjectsPage-Item' to={`/projects/${id}`}>
                  <div className='Item-Image'>
                    <img src={cover} alt='cover'/>
                  </div>
                  <p className='Item-Title'>{title}</p>
                </Link>
              })
            }
          </div>
        </div>
      </>
    }
    default: {
      <div></div>
    }
  }
}
