import axios from 'axios';

import { API_URL } from 'constants'

export function getAlbums() {
  return axios.get(`${API_URL}/api/v1/albums.json`)
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getAlbum(id) {
  return axios.get(`${API_URL}/api/v1/albums/${id}.json`)
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
