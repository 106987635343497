import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import cx from 'classnames';
import { connect } from 'react-redux';

import { getAlbum } from 'api/album';
import { formatDate } from 'utils/helper';

import './AlbumPage.scss';

import { setSongs, setSongIndex, setPlaying } from 'store/actions';

const AlbumPage = (props) => {
  const [album, setAlbum] = useState(null);

  const {
    songs,
    setSongs,
    songIndex,
    setSongIndex,
    playing,
    setPlaying,
    loading,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const album = await getAlbum(id);

      setAlbum(album);
    }

    fetchData();
  }, [id]);

  if (album) {
    const { title, description, cover, date_at } = album;
    const activeSongId = songs[songIndex]?.id;

    return <>
      <div className='AlbumPage'>
        <div className="Album-Information">
          <div className="Album-Group">
            <div className='album-title'>
              <h1>{title}</h1>
            </div>
            <p className="Album-Date">Дата: {formatDate(date_at)}</p>
            <p className="Album-Description">{description?.split(/\n/).map((line, index) => {
                return <span className='NewLine' key={index}>{line}</span>;
              })}
            </p>
          </div>
          <div className="Album-Image">
            <img src={cover} alt=''/>
          </div>
        </div>
        <div className='Album-Songs'>
          {
            album.songs.sort((a, b) => (a.number - b.number)).map((song, index) => {
              const { id, title, link, number } = song;

              return <div
                key={id}
                className={cx("Song", { "Song_Active": id === activeSongId })}
              >
                <div
                  className="Song-Title"
                  onClick={() => {
                    setPlaying(id !== activeSongId || !playing)
                    setSongs(album.songs);
                    setSongIndex(index);
                  }}
                >
                  {number}. {title} 
                  {
                    (id === activeSongId && playing && loading)
                      ? <SpinnerCircular size={10} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
                      : ''
                  }
                </div>
                <a className='Song-Actions' href={link} download target="_blank" rel="noreferrer">💾</a>
              </div>;
            })
          }
        </div>
      </div>
    </>
  } else {
    return <div className='Spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
    </div>
  }
}

export default connect(
  (state) => ({
    songs: state.main.songs,
    songIndex: state.main.songIndex,
    playing: state.main.playing,
    loading: state.main.loading,
  }),
  (dispatch) => ({
    setSongs: (songs) => dispatch(setSongs(songs)),
    setSongIndex: (index) => dispatch(setSongIndex(index)),
    setPlaying: (status) => dispatch(setPlaying(status)),
  })
)(AlbumPage);
