export const formatDate = (date) => (new Date(date)).toISOString().split('T')[0].split('-').reverse().join('.');

export const formatTime = (time) => {
  if (time === null || time === Infinity) {
    return '0:00'
  }

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
}
