import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import './AlbumsPage.scss';

import { getAlbums } from 'api/album';
import { loadingStatuses } from 'utils/constants';

export const AlbumsPage = () => {
  const [albums, setAlbums] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(() => {
    const fetchData = async () => {
      const albums = await getAlbums();

      setAlbums(albums);
      setLoadingStatus(loadingStatuses.LOADED);
    }

    fetchData();
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='Spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color='rgba(0, 0, 0, 1)' secondaryColor='rgba(255, 255, 255, 1)' />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <section className='AlbumsPage-Albums'>
        <h2 className='Title'>Альбомы</h2>
        <div className='Albums-List'>
          {
            albums.sort((a, b) => (new Date(b.date_at) - new Date(a.date_at))).map(({ id, title, cover, date_at }) => {
              return <Link key={id} className='Albums-Item' to={`/albums/${id}`}>
                <div className='Item-Image'>
                  <img src={cover} alt='cover'/>
                </div>
                <p className='Item-Title'>{title}</p>
                <p className='Item-Date'>{date_at.slice(0, 4)}</p>
              </Link>
            })
          }
        </div>
      </section>
    }
    default: {
      <div></div>
    }
  }
}
