import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

import './Player.scss';

import { setSongIndex, setPlaying, setLoading, setDuration, setTime, setError } from 'store/actions';

import { formatTime } from 'utils/helper';

const Player = (props) => {
  const {
    songs,
    songIndex,
    setSongIndex,
    playing,
    setPlaying,
    setLoading,
    duration,
    setDuration,
    time,
    setTime,
    error,
    setError,
  } = props;

  const [muted, setMuted] = useState(true);

  const playerRef = useRef();
  const progressRef = useRef();


  const playPrev = () => {
    const prevIndex = songIndex - 1;

    if (prevIndex < 0) {
      setSongIndex(songs[songs.length - 1]);
    } else {
      setSongIndex(prevIndex);
    }
  }

  const playNext = () => {
    const nextIndex = songIndex + 1;

    if (nextIndex >= songs.length) {
      setSongIndex(0);
    } else {
      setSongIndex(nextIndex);
    }
  }

  useEffect(() => {
    setDuration(null);
    setTime(null);

    if (songIndex != null) {
      setLoading(true);
      setMuted(false);
    }
  }, [songs, songIndex, setDuration, setTime, setLoading]);

  useEffect(() => {
    console.error(error)
  }, [error])

  const song = songs && songIndex !== null && songs[songIndex];

  return <div className="Player">
    <ReactPlayer
      ref={playerRef}
      className='ReactPlayer'
      url={song?.link || '/'}
      height='50px'
      config={{ file: { attributes: { preload: 'none' } } }}
      controls
      muted={muted}
      playing={playing}
      style={{ maxWidth: '100%' }}
      onEnded={playNext}
      onDuration={(duration) => { setDuration(duration) } }
      onProgress={(progress) => { setTime(progress.playedSeconds) } }
      onReady={(e) => { setLoading(false)}}
      onError={(e) => { setLoading(false); console.error(JSON.stringify(e)) }}
    />
    {
      song && (
        <div className='Player-Container'>
          <div className="Player-Group Player-Controls">
            <div className="Player-Prev" onClick={playPrev}>⏮︎</div>
            {
              playing
                ? <div className="Player-Pause"
                  onClick={() => { setPlaying(false) }}
                >
                  ⏸︎
                </div>
                : <div
                  className="Player-Play"
                  onClick={() => { setPlaying(true) }}
                >
                  ▶
                </div>
            }
            <div className="Player-Next" onClick={playNext}>⏭︎</div>
          </div>
          <div className="Player-Group Player-Progress">
            <div>
              {formatTime(time)}
            </div>
            <div
              className="Player-Duration"
              ref={progressRef}
              onClick={(e, data) => {
                const seek = (e.clientX - progressRef.current.offsetLeft) / progressRef.current.offsetWidth;

                playerRef.current.seekTo(seek);
                setTime(seek * duration);
              }}
            >
              <div
                className="Duration-Playing"
                style={{ width: `${(time || 0) / (duration || 1) * 100}%` }}
              ></div>
            </div>
            <div>
              {formatTime(duration)}
            </div>
          </div>
          <div className="Player-Group Player-Info">
            <div className="Player-Cover">
              <img src={song.album_cover} alt='cover'/>
            </div>
            <div className="Player-Title">
              <p className="Player-AlbumTitle">{song.album_title}</p>
              <p className="Player-SongTitle">
                {
                  `${song.number} - ${song.title}`
                }
              </p>
            </div>
          </div>
        </div>
      )
    }
  </div>
}

export default connect(
  (state) => ({
    songs: state.main.songs,
    songIndex: state.main.songIndex,
    playing: state.main.playing,
    duration: state.main.duration,
    time: state.main.time,
    error: state.main.error,
  }),
  (dispatch) => ({
    setSongIndex: (index) => dispatch(setSongIndex(index)),
    setPlaying: (status) => dispatch(setPlaying(status)),
    setLoading: (status) => dispatch(setLoading(status)),
    setDuration: (duration) => dispatch(setDuration(duration)),
    setTime: (time) => dispatch(setTime(time)),
    setError: (error) => dispatch(setError(error)),
  })
)(Player);
