import { Link } from 'react-router-dom';

import './Footer.scss';

import LogoIcon from 'assets/images/icons/logo.svg';
import ViolinImage from 'assets/images/violin.svg';
import YoutubeIcon from 'assets/images/icons/youtube.svg';
import FacebookIcon from 'assets/images/icons/facebook.svg';
import PatreonIcon from 'assets/images/icons/patreon.svg';
import PaypalIcon from 'assets/images/icons/paypal.svg';

export const Footer = () => {
  return (
    <footer className='Footer'>
      <div className='Footer-Container Container'>
        <img className='Footer-Image' src={ViolinImage} alt='footer'/>
        <div className='Footer-Information'>
          <div className='Footer-Logo'>
            <Link to="/">
              <img src={LogoIcon} alt='logo'/>
              <div className='Logo-Label'>
                <p>Тацяна</p>
                <p>Грыневіч-Матафонава</p>
              </div>
            </Link>
          </div>
          <div className='Footer-Socials'>
            <a href='https://www.youtube.com/channel/UCwIJKisuaiFbY8Yo73gJ7-Q' rel='noreferrer' target='_blank'>
              <img src={YoutubeIcon} alt='youtube'/>
            </a>
            <a href='https://www.facebook.com/profile.php?id=100002680600277' rel='noreferrer' target='_blank'>
              <img src={FacebookIcon} alt='facebook'/>
            </a>
            <a href='https://www.patreon.com/hrynievich' rel='noreferrer' target='_blank'>
              <img src={PatreonIcon} alt='patreon'/>
            </a>
            <a href='adam.matafonov@gmail.com' rel='noreferrer' target='_blank'>
              <img src={PaypalIcon} alt='paypal'/>
            </a>
          </div>
        </div>
        <div className='Footer-Copyright'>
          © Тацяна Грыневіч, 2022
        </div>
      </div>
    </footer>
  )
}
