import { createStore, combineReducers } from 'redux';
import * as reducers from './reducers';

const initialState = {
  main: {
    songs: [],
    currentSongIndex: null,
    playing: false,
    loading: false,
    duration: null,
    time: null,
    error: null,
  }
};

const store = createStore(
  combineReducers({ ...reducers }),
  initialState,
);

export default store;
