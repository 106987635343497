import { NavLink as Link } from 'react-router-dom';
import cx from 'classnames';
import './Header.scss';

import LogoIcon from 'assets/images/icons/logo.svg';
import YoutubeIcon from 'assets/images/icons/youtube.svg';
import FacebookIcon from 'assets/images/icons/facebook.svg';
import PatreonIcon from 'assets/images/icons/patreon.svg';
import PaypalIcon from 'assets/images/icons/paypal.svg';

export const Header = () => {
  return (
    <nav className='Header'>
      <div className='Header-Container Container'>
        <div className='Header-Logo'>
          <Link to="/">
            <img src={LogoIcon} alt='logo'/>
            <div className='Logo-Label'>
              <p>Тацяна</p>
              <p>Грыневіч-Матафонава</p>
            </div>
          </Link>
        </div>
        <div className='Header-Center'>
          <Link className={({ isActive }) => cx({ 'Active': isActive })} to="/albums">Альбомы</Link>
          <Link className={({ isActive }) => cx({ 'Active': isActive })} to="/projects">Праекты</Link>
          <Link className={({ isActive }) => cx({ 'Active': isActive })} to="/articles">Навіны</Link>
        </div>
        <div className='Header-Socials'>
          <a href='https://www.youtube.com/channel/UCwIJKisuaiFbY8Yo73gJ7-Q' rel='noreferrer' target='_blank'>
            <img src={YoutubeIcon} alt='youtube'/>
          </a>
          <a href='https://www.facebook.com/profile.php?id=100002680600277' rel='noreferrer' target='_blank'>
            <img src={FacebookIcon} alt='facebook'/>
          </a>
          <a href='https://www.patreon.com/hrynievich' rel='noreferrer' target='_blank'>
            <img src={PatreonIcon} alt='patreon'/>
          </a>
          <a href='adam.matafonov@gmail.com' rel='noreferrer' target='_blank'>
            <img src={PaypalIcon} alt='paypal'/>
          </a>
        </div>
      </div>
    </nav>
  )
}
